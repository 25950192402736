import React, { Component } from 'react';

import { observable, computed, reaction,action,decorate } from "mobx";
import { inject, observer, Provider } from "mobx-react";
import { withTranslation, Translation, } from 'react-i18next';

import {Link} from "react-router-dom";
//import { Fade } from 'react-slideshow-image';
import PubSub from 'pubsub-js';

//import MetaTags from 'react-meta-tags';

import {BrowserView,MobileView,TabletView, isMobileOnly, isTablet} from 'react-device-detect';
//isBrowser,isMobile, isTablet

import HeaderPC from "../../header/HeaderPC";
import HeaderMobile from "../../header/HeaderMobile";
import HeaderTablet from "../../header/HeaderTablet";

//import HeaderConnectedPC from "../../header/HeaderConnectedPC";

import   FooterPC from "../../footer/FooterPC";
import   FooterMobile from "../../footer/FooterMobile";
import   FooterTablet from "../../footer/FooterTablet";

import i18n from "../../../i18n";

import {generateIPForREST} from "../../../actions";

import { isLogin } from '../../../utils';

const fadeImages = [
  'https://s3.amazonaws.com/debyooinc16.img/home/home_img_stories.png',
  'https://s3.amazonaws.com/debyooinc16.img/home/Publish_SP4_06.png',
  'https://s3.amazonaws.com/debyooinc16.img/home/home_img_feels4.png'
];

const fadeProperties = {
  duration: 3500,
  transitionDuration: 500,
  infinite: true,
  arrows: false,
  indicators: false
}

const viewportWidth = window.innerWidth;
const viewportHeight = window.innerHeight;

//@inject("appStore")
//@observer
class SupportSystemStatusPage extends Component {
  constructor(props) {

    super(props);


    var _h = viewportHeight*0.4;
    var _w = _h*16/9;

    if (viewportWidth > viewportHeight)
    {
      var _tb = viewportHeight*1.8;
      var _mb = viewportHeight*2.2;

    }
    else
    {
      var _tb = viewportHeight*0.9;
      var _mb = viewportHeight*0.85;

    }

    this.state = {
      iFrameWidth:  _w+'px',
      iFrameHeight:  _h+'px',
      bgImageHeight: viewportHeight*0.4,
      bgImageMobileHeight: _mb*0.5,
      bgImageTabletHeight: _tb*0.5,
      currentYear: new Date().getFullYear()
    };

  }

  componentWillMount(){
    document.body.style.backgroundColor = "#fff";
    // document.body.style.backgroundRepeat = 'no-repeat';
    // document.body.style.backgroundAttachment = 'fixed';

    window.addEventListener("orientationchange", function() {
      // Announce the new orientation number
      window.location.reload();
    }, false);
  }

  componentWillUnmount(){
    document.body.style.backgroundColor = null;
    // document.body.style.backgroundRepeat = null;
    // document.body.style.backgroundAttachment = null;
  }

  setLocale(lang) {



    console.log("Locale Lang:" +lang)
    //  this.props.appStore.locale = lang;

    localStorage.setItem("language", lang);
    i18n.changeLanguage(lang);

    PubSub.publish('handleLanguageChange', lang);

    if (isLogin())
    {
      this.saveLanguageToServer(lang);
    }

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }



  saveLanguageToServer(lang) {
    //var ip = "https://www.debyoo.com/";
    var ip = generateIPForREST();

    var jwtToken = localStorage.getItem("jwtToken") || '';
    //  this.props.setLanguage(responseData.country_code);
    fetch(ip+'save_language_to_server_api', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': jwtToken,
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({
        language: lang
      })
    })
    .then((response) => response.json())
    .then((responseData) => {
      console.log("Language saved on server");

    })
    .catch(function(error) {

    }.bind(this));

  }


  callbackHeader = (dataFromHeader) => {
    console.log("Good start but not yet there...: " + dataFromHeader);

    this.props.relayCallbackHeaderFromParent(dataFromHeader);
  }


  render() {
    //console.log("isMobile:" + isMobile)

    //	const { t, i18n } = useTranslation();

    /*	   const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    };*/

    return (
      <Translation>
        { t =>
          <div style={{background: '#fff'}}>

            <BrowserView>



              <HeaderPC store={this.props.store} pathname={this.props.pathname}/>


              <div className="clear-both h40" style={{height: 60}}/>



              <div className="support_div_bgimg_wrapper" style={{width: '100%', height: this.state.bgImageHeight, backgroundColor: '#000'}}>
                <div style={{backgroundImage: 'url(https://d3van47cb9u9tj.cloudfront.net/about/debyoo_support.jpg)', backgroundPosition: '-100px center',
                  backgroundSize: 'cover', backgroundRepeat: 'no-repeat',
                  position: 'relative', width: '100%', height: this.state.bgImageHeight, overflow: 'hidden', marginTop: '0px', zIndex: 0}}>
                </div>
              </div>

              <div style={{width: '100%', margin: 'auto', textAlign: 'center', background: '#fff', paddingTop: '50px', paddingBottom: '0px'}}>
                <div style={{width: '1000px', margin: 'auto', textAlign: 'center', background: '#fff', fontFamily: 'Lato', fontSize: '40px', color: '#555'}}>
                  {i18n.language == "fr" &&
                    <div>Bienvenue dans l’assistance de Debyoo ID.</div>
                  }
                  {i18n.language == "en" &&
                    <div> Welcome to Debyoo ID Support.</div>
                  }

                  <div style={{fontSize: '20px', marginTop: '70px'}}>
                    <b>{t('General_inquiries')}</b>: <a href="mailto:info@debyoo.com" style={{fontWeight: 'normal', color: '#31579d'}}>info@debyoo.com</a> <br /> <br />
                    <b>{t('Support')}</b>: <a href="mailto:info@debyoo.com" style={{fontWeight: 'normal', color: '#31579d'}}>support@debyoo.com</a> <br /> <br />
                  </div>


                  <div className="clear-both" style={{height: '50px'}}/>



                  {i18n.language == "fr" && 1==2 &&
                    <div style={{width: '600px', margin: 'auto',fontSize: '22px', fontWeight: 'bold'}}>Comment faire une demande d'adhésion et devenir membre de la Youth Initiative Jeunesse si vous avez déjà un compte Debyoo?</div>
                  }
                  {i18n.language == "en" &&  1==2 &&
                    <div style={{width: '600px', margin: 'auto', fontSize: '22px', fontWeight: 'bold'}}>How to apply for membership if you already have a Debyoo account?</div>
                  }

                    <div className="clear-both" style={{height: '20px'}}/>

                  <div style={{fontSize: '16px'}}>
                       <div style={{width: '300px', float: 'left',}}>
                        <div><img src={`https://d3van47cb9u9tj.cloudfront.net/debyooid/screenshot_5_5_iphone_1_${i18n.language}.jpg`} style={{width: '100%', border: '1px solid #ccc'}}/></div>
                          {i18n.language == "fr" && 1==2 &&
                            <div style={{marginTop: '10px'}}>Appuyez «Se connecter»</div>
                          }
                          {i18n.language == "en" && 1==2 &&
                            <div style={{marginTop: '10px'}}>Tap «Sign in»</div>
                          }
                      </div>
                      <div style={{width: '300px', float: 'left', marginLeft: '48px'}}>
                        <div><img src={`https://d3van47cb9u9tj.cloudfront.net/debyooid/screenshot_5_5_iphone_2_${i18n.language}.jpg`} style={{width: '100%', border: '1px solid #ccc'}}/></div>
                          {i18n.language == "fr" && 1==2 &&
                            <div style={{marginTop: '10px'}}>Connectez-vous avec vos identifiants</div>
                          }
                          {i18n.language == "en" && 1==2 &&
                            <div style={{marginTop: '10px'}}>Sign in with your credentials</div>
                          }
                      </div>
                      <div style={{width: '300px', float: 'left', marginLeft: '48px'}}>
                        <div><img src={`https://d3van47cb9u9tj.cloudfront.net/debyooid/screenshot_5_5_iphone_3_${i18n.language}.jpg`} style={{width: '100%', border: '1px solid #ccc'}}/></div>
                          {i18n.language == "fr" && 1==2 &&
                            <div style={{marginTop: '10px'}}>Appuyez «Ma carte»</div>
                          }
                          {i18n.language == "en" && 1==2 &&
                            <div style={{marginTop: '10px'}}>Tap «My card»</div>
                          }
                      </div>
                      <div className="clear-both" style={{height: '50px'}}/>
                  </div>

                </div>
              </div>



              <div className="clear-both" style={{height: 100}}/>

               <div style={{background: '#fff', paddingTop: 5, paddingBottom: 0, borderRadius: 4}}>
               <FooterPC />
               </div>




        </BrowserView>

        {isMobileOnly &&
          <MobileView>


              <HeaderMobile store={this.props.store}/>


            <div className="clear-both" style={{height: '60px'}}/>



            <div className="support_div_bgimg_wrapper" style={{height: this.state.bgImageMobileHeight}}>
              <div style={{backgroundImage: 'url(https://d3van47cb9u9tj.cloudfront.net/about/debyoo_support.jpg)', backgroundSize: 'cover',
                position: 'relative', width: '100%', height: this.state.bgImageMobileHeight, overflow: 'hidden', marginTop: '0px', zIndex: 0}}>
              </div>
            </div>


            <div style={{width: '100%', margin: 'auto', textAlign: 'center', background: '#fff', paddingTop: '30px', paddingBottom: '0px'}}>
              <div style={{width: '96%', margin: 'auto', fontFamily: 'Lato', textAlign: 'center', background: '#fff', fontSize: '18px', color: '#555'}}>
                {i18n.language == "fr" &&
                  <div>Bienvenue dans l’assistance de Debyoo ID.</div>
                }
                {i18n.language == "en" &&
                  <div> Welcome to Debyoo ID Support.</div>
                }

                <div style={{fontSize: '15px', marginTop: '40px'}}>
                  <b>{t('General_inquiries')}</b>: <a href="mailto:info@debyoo.com" style={{fontWeight: 'normal', color: '#31579d'}}>info@debyoo.com</a> <br />
                  <b>{t('Support')}</b>: <a href="mailto:info@debyoo.com" style={{fontWeight: 'normal', color: '#31579d'}}>support@debyoo.com</a> <br /> <br />
                </div>


                <div className="clear-both" style={{height: '30px'}}/>



                {i18n.language == "fr" && 1==2 &&
                  <div style={{width: '98%', margin: 'auto',fontSize: '16px', fontWeight: 'bold'}}>Comment faire une demande d'adhésion et devenir membre de la Youth Initiative Jeunesse si vous avez déjà un compte Debyoo?</div>
                }
                {i18n.language == "en" && 1==2 &&
                  <div style={{width: '98%', margin: 'auto', fontSize: '16px', fontWeight: 'bold'}}>How to apply for membership if you already have a Debyoo account?</div>
                }

                  <div className="clear-both" style={{height: '20px'}}/>

                <div style={{fontSize: '14px', width: '100%',}}>
                     <div style={{width: '48%', float: 'left',}}>
                      <div><img src={`https://d3van47cb9u9tj.cloudfront.net/debyooid/screenshot_5_5_iphone_1_${i18n.language}.jpg`} style={{width: '100%', border: '1px solid #ccc'}}/></div>
                        {i18n.language == "fr" && 1==2 &&
                          <div style={{marginTop: '10px'}}>Appuyez «Se connecter»</div>
                        }
                        {i18n.language == "en" && 1==2 &&
                          <div style={{marginTop: '10px'}}>Tap «Sign in»</div>
                        }
                    </div>
                    <div style={{width: '48%', float: 'right'}}>
                      <div><img src={`https://d3van47cb9u9tj.cloudfront.net/debyooid/screenshot_5_5_iphone_2_${i18n.language}.jpg`} style={{width: '100%', border: '1px solid #ccc'}}/></div>
                        {i18n.language == "fr" && 1==2 &&
                          <div style={{marginTop: '10px'}}>Connectez-vous avec vos identifiants</div>
                        }
                        {i18n.language == "en" && 1==2 &&
                          <div style={{marginTop: '10px'}}>Sign in with your credentials</div>
                        }
                    </div>

                      <div className="clear-both" style={{height: '50px'}}/>

                    <div style={{width: '48%', float: 'left'}}>
                      <div><img src={`https://d3van47cb9u9tj.cloudfront.net/debyooid/screenshot_5_5_iphone_3_${i18n.language}.jpg`} style={{width: '100%', border: '1px solid #ccc'}}/></div>
                        {i18n.language == "fr" && 1==2 &&
                          <div style={{marginTop: '10px'}}>Appuyez «Ma carte»</div>
                        }
                        {i18n.language == "en" && 1==2 &&
                          <div style={{marginTop: '10px'}}>Tap «My card»</div>
                        }
                    </div>
                    <div style={{width: '48%', float: 'right'}}>
                      <div><img src={`https://d3van47cb9u9tj.cloudfront.net/debyooid/screenshot_5_5_iphone_4_${i18n.language}.jpg`} style={{width: '100%', border: '1px solid #ccc'}}/></div>
                        {i18n.language == "fr" && 1==2 &&
                          <div style={{marginTop: '10px'}}>Appuyez «Devenir membre» et puis suivez les instructions</div>
                        }
                        {i18n.language == "en" && 1==2 &&
                          <div style={{marginTop: '10px'}}>Tap «Become a member» and then follow instructions</div>
                        }
                    </div>
                    <div className="clear-both" style={{height: '50px'}}/>
                </div>


              </div>
            </div>



            <div className="clear-both" style={{height: '100px'}}/>

             <div style={{background: '#fff', paddingTop: 5, paddingBottom: 0, borderRadius: 4}}>
             <FooterMobile />
             </div>
          </MobileView>
        }

        {isTablet &&
          <TabletView>


              <HeaderTablet store={this.props.store}/>
            

            <div className="clear-both" style={{height: '60px'}}/>

            <div className="support_div_bgimg_wrapper" style={{height: this.state.bgImageTabletHeight}}>
              <div  style={{backgroundImage: 'url(https://d3van47cb9u9tj.cloudfront.net/about/debyoo_support.jpg)', backgroundSize: 'cover',
                position: 'relative', width: '100%', height: this.state.bgImageTabletHeight, overflow: 'hidden', marginTop: '0px', zIndex: 0}}>
              </div>
            </div>


            <div style={{width: '100%', margin: 'auto', textAlign: 'center', background: '#fff', paddingTop: '40px', paddingBottom: '0px'}}>
              <div style={{width: '600px', margin: 'auto', fontFamily: 'Lato', textAlign: 'center', background: '#fff', fontSize: '30px', color: '#555'}}>
                  {i18n.language == "fr" &&
                    <div>Bienvenue dans l’assistance de Debyoo ID.</div>
                  }
                  {i18n.language == "en" &&
                    <div> Welcome to Debyoo ID Support.</div>
                  }


                  <div style={{fontSize: '20px', marginTop: '70px'}}>
                    <b>{t('General_inquiries')}</b>: <a href="mailto:info@debyoo.com" style={{fontWeight: 'normal', color: '#31579d'}}>info@debyoo.com</a> <br /> <br />
                    <b>{t('Support')}</b>: <a href="mailto:info@debyoo.com" style={{fontWeight: 'normal', color: '#31579d'}}>support@debyoo.com</a> <br /> <br />
                  </div>


                  <div className="clear-both" style={{height: '50px'}}/>



                  {i18n.language == "fr" && 1==2 &&
                    <div style={{width: '600px', margin: 'auto',fontSize: '22px', fontWeight: 'bold'}}>Comment faire une demande d'adhésion et devenir membre de la Youth Initiative Jeunesse si vous avez déjà un compte Debyoo?</div>
                  }
                  {i18n.language == "en" && 1==2 &&
                    <div style={{width: '600px', margin: 'auto', fontSize: '22px', fontWeight: 'bold'}}>How to apply for membership if you already have a Debyoo account?</div>
                  }

                    <div className="clear-both" style={{height: '20px'}}/>

                  <div style={{fontSize: '16px'}}>
                       <div style={{width: '280px', float: 'left',}}>
                        <div><img src={`https://d3van47cb9u9tj.cloudfront.net/debyooid/screenshot_5_5_iphone_1_${i18n.language}.jpg`} style={{width: '100%', border: '1px solid #ccc'}}/></div>
                          {i18n.language == "fr" && 1==2 &&
                            <div style={{marginTop: '10px'}}>Appuyez «Se connecter»</div>
                          }
                          {i18n.language == "en" && 1==2 &&
                            <div style={{marginTop: '10px'}}>Tap «Sign in»</div>
                          }
                      </div>
                      <div style={{width: '280px', float: 'right'}}>
                        <div><img src={`https://d3van47cb9u9tj.cloudfront.net/debyooid/screenshot_5_5_iphone_2_${i18n.language}.jpg`} style={{width: '100%', border: '1px solid #ccc'}}/></div>
                          {i18n.language == "fr" && 1==2 &&
                            <div style={{marginTop: '10px'}}>Connectez-vous avec vos identifiants</div>
                          }
                          {i18n.language == "en" && 1==2 &&
                            <div style={{marginTop: '10px'}}>Sign in with your credentials</div>
                          }
                      </div>

                        <div className="clear-both" style={{height: '50px'}}/>

                      <div style={{width: '280px', float: 'left'}}>
                        <div><img src={`https://d3van47cb9u9tj.cloudfront.net/debyooid/screenshot_5_5_iphone_3_${i18n.language}.jpg`} style={{width: '100%', border: '1px solid #ccc'}}/></div>
                          {i18n.language == "fr" && 1==2 &&
                            <div style={{marginTop: '10px'}}>Appuyez «Ma carte»</div>
                          }
                          {i18n.language == "en" && 1==2 &&
                            <div style={{marginTop: '10px'}}>Tap «My card»</div>
                          }
                      </div>
                      <div style={{width: '280px', float: 'right'}}>
                        <div><img src={`https://d3van47cb9u9tj.cloudfront.net/debyooid/screenshot_5_5_iphone_4_${i18n.language}.jpg`} style={{width: '100%', border: '1px solid #ccc'}}/></div>
                          {i18n.language == "fr" && 1==2 &&
                            <div style={{marginTop: '10px'}}>Appuyez «Devenir membre» et puis suivez les instructions</div>
                          }
                          {i18n.language == "en" && 1==2 &&
                            <div style={{marginTop: '10px'}}>Tap «Become a member» and then follow instructions</div>
                          }
                      </div>
                      <div className="clear-both" style={{height: '50px'}}/>
                  </div>


              </div>
            </div>


            <div className="clear-both" style={{height: 100}}/>

             <div style={{background: '#fff', paddingTop: 5, paddingBottom: 0, borderRadius: 4}}>
             <FooterTablet />
             </div>

          </TabletView>
        }

      </div>
    }
  </Translation>
)
}
}



export default SupportSystemStatusPage;
