import React, { Component } from 'react';

import { observable, computed, reaction,action,decorate } from "mobx";
import { inject, observer, Provider } from "mobx-react";
import { withTranslation, Translation, } from 'react-i18next';

import {Link} from "react-router-dom";
import { Fade } from 'react-slideshow-image';

//import MetaTags from 'react-meta-tags';

import {BrowserView,MobileView,TabletView} from 'react-device-detect';
//isBrowser,isMobile, isTablet

import HeaderPC from "../../header/HeaderPC";
import HeaderConnectedPC from "../../header/HeaderConnectedPC";
import   FooterPC from "../../footer/FooterPC";

import { isLogin } from '../../../utils';

const fadeImages = [
  'https://s3.amazonaws.com/debyooinc16.img/home/home_img_stories.png',
  'https://s3.amazonaws.com/debyooinc16.img/home/Publish_SP4_06.png',
  'https://s3.amazonaws.com/debyooinc16.img/home/home_img_feels4.png'
];
 
const fadeProperties = {
  duration: 3500,
  transitionDuration: 500,
  infinite: true, 
  arrows: false,
  indicators: false
}

//@inject("appStore")
@observer
class EulaPage extends Component {
    constructor(props) {
		
          super(props);
	   
   	
			
   	 this.state = {
		 currentYear: new Date().getFullYear()
   	};
	
     }
	 
    setLocale(lang) {

  	  console.log("Locale Lang:" +lang)
           this.props.appStore.locale = lang;
    }
	
	
  render() {
	//console.log("isMobile:" + isMobile)
	  
  //	const { t, i18n } = useTranslation();

  /*	   const changeLanguage = lng => {
  	      i18n.changeLanguage(lng);
  	};*/
	  
    return (
    <Translation>
	    { t =>
	         <div style={{backgroundColor: '#e4e5e6'}}>
          		
					<BrowserView>
			
			
			                   {isLogin() && 1==2 &&
							<HeaderConnectedPC callbackHeaderFromParent={this.callbackHeader} store={this.props.store}/>
					      }

					         {!isLogin() && 
							<HeaderPC store={this.props.store}/>
					         }
			
			

			                      <div className="clear-both h40" style={{height: 60}}/>
			                    
				                    <div className="white-round-container" style={{margin: 'auto', marginTop: 60, width: '1000px'}}>
			                                   <div className="padding-20">
						                    <div className="page-title">
			                                                  {t('Eulagreement')}
						                     </div>
									
									<br/><br/>	
									PLEASE READ CAREFULLY. THIS "DEBYOO SERVICE TERMS AND CONDITIONS" ("AGREEMENT") IS A LEGALLY BINDING AGREEMENT BETWEEN EACH USER (INCLUDING ANY SUBSCRIBER, FREE TRIAL USER, OR PURCHASER OF ANY DEBYOO SERVICE REQUIRING PAYMENT OF A FEE) ("YOU" AND "YOUR") AND DEBYOO INC ("DEBYOO," "WE," "US," OR "OUR"). BY CLICKING THE "I AGREE" BUTTON OR BY USING ANY SERVICES, WHETHER FOR A FEE OR FOR FREE, AND WHETHER ON A PERMANENT OR TRIAL BASIS, YOU ARE INDICATING THAT YOU HAVE READ THIS AGREEMENT, THAT YOU UNDERSTAND IT, AND THAT YOU CONSENT TO BE BOUND BY ALL OF ITS TERMS AND CONDITIONS. THIS AGREEMENT SETS FORTH YOUR RIGHTS AND OBLIGATIONS WITH RESPECT TO YOUR USE OF THE DEBYOO SERVICE(S) THAT YOU HAVE SELECTED, AND YOUR USE OF AND ACCESS TO ANY SERVICE PROVIDED BY DEBYOO THROUGH THE DEBYOO SERVICE OR THE DEBYOO APPLICATION. AS USED HEREIN, "SERVICES" INCLUDES ANY VERSION OF THE DEBYOO SERVICES PROVIDED BY DEBYOO THROUGH THE DEBYOO SITE AT  <a href="/">www.debyoo.com</a> (THE “SITE”). 	
															   
									  <br/><br/>				  
															  
						         		<b>1.  Notice</b>
									 <br/><br/>

								           Debyoo may from time to time modify these terms and post a copy of the amended Agreement at the Site. If you do not agree to (or cannot comply with) the Agreement as amended, your only remedy is to stop using the Service or, if applicable, cancel your subscription service, if any. You will be deemed to have accepted the Agreement as amended if you continue to use the Service after any amendments are posted.
									<br/><br/>


									<b>2. Age Requirement</b>
									<br/><br/>
															  
															  
									To register for use of the Service on your own behalf, you must be at least 13 years of age. Children under 13 years of age may not register for the Service, nor may parents or legal guardians register on their behalf. If you are a parent or legal guardian entering this Agreement for the benefit of your child or a child in your legal care, be aware that you are fully responsible for the child's use of the Service, including all financial charges and legal liability that he or she may incur.
									 <br/><br/>


									<b>3. Registration Information</b>
									<br/><br/>

									To use the Service you must register and provide certain information (e.g. a member (user) name, password and valid email address) to Debyoo ("Registration Information") for the purposes of creating an account, called "My Account". You agree to provide current, complete, and accurate Registration Information at the time you register and you will update your Registration Information as necessary to keep it current, complete and accurate. Debyoo may terminate your account and any or all rights to the Service if any information you provide is inaccurate, false, or incomplete. You agree that Debyoo may store and use the Registration Information you provide for use in billing fees to your credit card and maintaining your accounts.
				                                  <br/><br/>

									<b>4.  Security of Account</b>
									<br/><br/>

									You are solely responsible for maintaining the confidentiality and security of your account. You agree to notify Debyoo immediately of any unauthorized use of your password and/or account. Debyoo shall not be responsible for any losses arising out of the unauthorized use of your Registration Information or account, and you agree to hold harmless and to indemnify Debyoo, its partners, parents, subsidiaries, agents, affiliates and/or licensors, as applicable, for any improper, unauthorized or illegal uses of the same. You may not attempt to gain unauthorized access to the Site. Should you attempt to do so, assist others in making such attempts, or distributing instructions, software or tools for that purpose, Debyoo will terminate your account.
									 <br/><br/>


									<b>5.   Consent to Our Communication with You by E-mail</b>
									<br/><br/>

									By establishing a Debyoo account, you grant permission for Debyoo to contact you at your e-mail address.
								     <br/><br/>


									<b>6.    Violation of Intellectual Property Rights</b>
									<br/><br/>

									If Debyoo receives a notice alleging that you have engaged in behavior that infringes Debyoo's or other's intellectual property rights or reasonably suspects the same, Debyoo may suspend or terminate your account without notice to you, and shall have no liabilities to you, past or present, for suspension or termination under this paragraph.
									<br/><br/>

					  				<b>7.	Objectionable Materials</b><br/>

					  				When using the Service, you understand that you might encounter Content that may be deemed objectionable, indecent, or offensive, which content may or may not be identified as having explicit language. You agree to use the Service at your own risk and Debyoo shall have no liability to you for Content that may be found objectionable, indecent, or offensive.

					  				USE OF SERVICE, CONTENT, SOFTWARE AND MATERIALS All rights not expressly granted to you in this Agreement are reserved to Debyoo.

					  				<br/><br/>

					  				<b>8. Technology Limitations</b><br/>

					  				Debyoo will make reasonable efforts to keep the Debyoo Site operational. However, certain technical difficulties, routine site maintenance/upgrades and any other events outside the control of Debyoo may, from time to time, result in temporary service interruptions. Debyoo also reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, functions of the Service with or without notice. You agree that Debyoo shall not be liable to you or to any third party for any of the direct or indirect consequences of any modification, suspension, discontinuance of or interruption to the Service. 

					  				<br/><br/>
					  				<b>9. Use Limitations</b><br/>

					  				You are prohibited from violating or attempting to violate the security of the Service, including, without limitation:  <br/>

					  				a. accessing data not intended for you or logging onto a processor, communications or access device or account which you are not authorized to access;  <br/>
					  				b. attempting to probe, scan or test the vulnerability of the Service or to breach security or authentication measures, regardless of your motives or intent;  <br/>
					  				c. reverse look-up, trace or seek to trace any information on any other user of or visitor to any Debyoo website or Services, or exploit the Service or any service or information made available ro offered by or through the Site, in any way where the purpose is to reveal any third party information;	 <br/>
					  				d. attempting to interfere with or disrupt the Service or service to any user, processor, host or network, including, without limitation, by submitting a virus, worm or Trojan horse;  <br/>
					  				d. take any action that imposes an unreasonable or disproportionately large load on the infrastructure of Debyoo’s websites, Service or other systems; <br/>
					  				e. sending unsolicited e-mail or other information, including promotions or advertising. Violations of system or network security or this Agreement may result in civil or criminal liability.  <br/>

					  				We have the right to investigate occurrences, which may involve such violations and we may involve, provide information to and cooperate with, law enforcement authorities in prosecuting users who are involved in such violations.

					  				<br/><br/>
					  				<b>10. Third Party Websites</b><br/>

					  				The Service may include products, content, and services from third parties available via Debyoo. As well, Debyoo may include links to third party websites, which are provided solely as a convenience to you. Debyoo assumes no liability or responsibility for third-party materials or websites, and you acknowledge and agree that Debyoo is not responsible for evaluating or examining the content or accuracy of any such third-party material or websites.

					  				<br/><br/>
					  				<b>11. Privacy Policy</b><br/>

					  				For additional information regarding Debyoo's use of information collected in connection with the Service, please refer to Debyoo's Privacy Policy, a copy of which is available at <a href="/privacy">www.debyoo.com/privacy</a> and which is expressly made part of this Agreement.


					  				<br/><br/>
					  				<b>12. Termination</b><br/>

					  				Termination By Debyoo<br/>

					  				You agree that Debyoo, in its sole discretion, with or without prior notice, may freeze or terminate your user name, password or use of the Application and/or Service(s) (or any part thereof) for any reason, including, without limitation, if Debyoo believes that you have violated or acted inconsistently with the letter or spirit of this Agreement. Debyoo may also in its sole discretion and at any time modify or discontinue providing the Software and/or any Service, or any part thereof, with or without notice. Further, you agree that Debyoo shall not be liable to you or any third-party for any interference with or termination of your access to the Software and/or any Service.

					  				<br/><br/>
					  				<b>13. Disclaimer of Warranty</b><br/>

					  				THE SOFTWARE AND CONTENT (TOGETHER, THE “APPLICATION”) IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT ANY REPRESENTATION OR WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE APPLICATION OR ITS OPERATION. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, Debyoo AND ITS LICENSORS, AND ITS DISTRIBUTION AND SYNDICATION PARTNERS, DISCLAIM ALL WARRANTIES, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NONINFRINGEMENT.

					  				<br/><br/>
					  				<b>14. Limitation of Liability</b><br/>

					  				THE ENTIRE RISK ARISING OUT OF THE USE AND/OR PERFORMANCE OF THE SERVICE(S) REMAINS WITH YOU. IN NO EVENT SHALL Debyoo OR ITS LICENSORS OR ANY OF THEIR OFFICERS, DIRECTORS, EMPLOYEES, AGENTS OR AFFILIATES BE LIABLE FOR ANY CONSEQUENTIAL, INCIDENTAL, DIRECT, INDIRECT, SPECIAL, PUNITIVE, OR OTHER DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, LOSS OF BUSINESS INFORMATION, OR OTHER PECUNIARY LOSS) ARISING OUT OF THIS AGREEMENT OR THE USE OF OR INABILITY TO USE ANY SERVICE, EVEN IF Debyoo HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU, BUT THEY SHALL APPLY TO THE MAXIMUM EXTENT PERMITTED BY LAW. IN NO EVENT SHALL WE OR ANY OF OUR LICENSORS BE LIABLE FOR ANY DAMAGES IN EXCESS OF THE AMOUNT PAID FOR THE APPLICATION OR THE SPECIFIC ITEM OF CONTENT GIVING RISE TO THE APPLICABLE CLAIM FOR DAMAGES.

					  				<br/><br/>
					  				<b>15. Indemnification</b><br/>

					  				You agree to indemnify and hold Debyoo and its parents, members, subsidiaries, affiliates, service providers, syndicators, distributors, licensors, officers, directors and employees, harmless against any losses, expenses, costs or damages (including our reasonable attorneys’ fees, expert fees’ and other reasonable cost of litigation or proceedings) arising from, incurred as a result of, or in any manner related to any claim or action based upon (a) your breach of, or failure to comply with, the terms and conditions of this Agreement (b) your use of the Service, and/or (c) the use of the Service by any other person using your IDs. We may, in our discretion, participate in the defense of any such claim or action and any negotiations for its settlement or compromise. No settlement which may adversely affect our rights or obligations shall be made without our prior written approval. We reserve the right, at our own expense and on notice to you, to assume exclusive defense and control of any such claim or action and then your corresponding indemnification obligation will end.

					  				<br/><br/>
					  				<b>16. Injunctive Relief</b><br/>

					  				You agree that any unauthorized use of any Site, the Service, the Materials, the Application or any related software or materials would result in irreparable injury to Debyoo and/or its affiliates or licensors for which money damages would be inadequate, and in such event and notwithstanding anything to the contrary as contained in this Agreement, Debyoo, its affiliates and/or licensors, as applicable, shall have the right, in addition to other remedies available at law and in equity, to immediate injunctive relief against you, without the necessity of posting a bond or proving the inadequacy of monetary damages, and shall be entitled to the reimbursement of all costs and expenses incurred in connection with the enforcement of its rights pursuant to this paragraph. Nothing contained in this Agreement shall be construed to limit remedies available pursuant to statutory or other claims that Debyoo, its affiliates and/or licensors may have under separate legal authority.

					  				<br/><br/>
					  				<b>17. Enforcement of Terms</b><br/>

					  				Debyoo reserves the right to take steps Debyoo believes are reasonably necessary or appropriate to enforce and/or verify compliance with any part of this Agreement (including but not limited to Debyoo's right to cooperate with any legal process relating to your use of the Service and/or products, and/or a third party claim that your use of the Service and/or products is unlawful and/or infringes such third party's rights). You agree that Debyoo has the right, without liability to you, to disclose any Registration Information and/or account information to law enforcement authorities, government officials, and/or a third party, as Debyoo believes is reasonably necessary or appropriate to enforce and/or verify compliance with any part of this Agreement (including but not limited to Debyoo's right to cooperate with any legal process relating to your use of the Service and/or products, and/or a third party claim that your use of the Service and/or products is unlawful and/or infringes such third party's rights).

					  				<br/><br/>
					  				<b>18. Notices</b><br/>

					  				Debyoo may send you notice with respect to the Service by sending an email message to the email address listed in your Debyoo account contact information, by sending a letter via postal mail to the contact address listed in your Debyoo account contact information, or by a posting on the Service. Notices shall become effective immediately.

					  				<br/><br/>
					  				<b>19. Governing Law; Dispute Resolution</b><br/>
									
									You agree that all matters relating to your access to or use of the Site, including all disputes, will be governed by the laws of the United States and by the laws of the State of New York without regard to its conflicts of laws provisions. You agree to the personal jurisdiction by and venue in the state and federal courts in New York County, New York, and waive any objection to such jurisdiction or venue. Any claim under these Terms and Conditions must be brought within one (1) year after the cause of action arises, or such claim or cause of action is barred. No recovery may be sought or received for damages other than out-of-pocket expenses, except that the prevailing party will be entitled to costs and reasonable attorneys’ fees. In the event of any controversy or dispute between Debyoo and you arising out of or in connection with your use of the Site, the parties shall attempt, promptly and in good faith, to resolve any such dispute. If we are unable to resolve any such dispute within a reasonable time (not to exceed thirty (30) days), then either party may submit such controversy or dispute to mediation. If the dispute cannot be resolved through mediation, then the parties shall be free to pursue any right or remedy available to them under applicable law.

									<br/><br/>
									<b>20. General</b><br/>

									No delay or failure to take action under this Agreement shall constitute any waiver by Debyoo of any provision of this Agreement.
									If any provision of this Agreement is invalid or unenforceable under applicable law, it is, to that extent, deemed omitted and the remaining provisions will continue in full force and effect. This Agreement will bind and inure to the benefit of each party's permitted successors and assigns. This Agreement is personal to you and may not be transferred, assigned or delegated to anyone. Any attempt by you to assign, transfer or delegate this Agreement shall be null and void. This Agreement (including all documents expressly incorporated herein by reference, including but not limited to the Debyoo Privacy Policy) constitutes the complete and exclusive agreement between Debyoo and you with respect to the subject matter hereof and supersedes all prior oral or written understandings, communications or agreements not specifically incorporated herein.

									<br/><br/>
									
									<br/><br/><br/>
					

									Last modified: December, 2018				  
															  
									
															  
								</div>
				                     </div>
			                   
			
						
			
							 <div className="clear-both" style={{height: 100}}/>							  
							 
							  <div style={{background: '#fff', paddingTop: 5, paddingBottom: 0, borderRadius: 4}}>
								<FooterPC />
							  </div>
	
					</BrowserView>
			
					<MobileView>
					    <h1> This is rendered only on mobile </h1>
					</MobileView>
			
					<TabletView>
					    <h1> This is rendered only on tablet </h1>
					</TabletView>
			        </div>
	  }
	 </Translation>
    )
  }
}



export default EulaPage;
