import React, { Component } from 'react';

import { observable, computed, reaction,action,decorate } from "mobx";
import { inject, observer, Provider } from "mobx-react";
import { withTranslation, Translation, } from 'react-i18next';

import { connect } from "react-redux";



import {Link} from "react-router-dom";



import {changeLanguage} from "../../../actions/changeLanguage";



import FooterPC from "../../footer/FooterPC";
import FooterMobile from "../../footer/FooterMobile";
import FooterTablet from "../../footer/FooterTablet";

import i18n from "../../../i18n";

//import MetaTags from 'react-meta-tags';

import {BrowserView,MobileView,TabletView, isMobileOnly, isTablet} from 'react-device-detect';
//isBrowser,isMobile, isTablet

const viewportWidth = window.innerWidth;
const viewportHeight = window.innerHeight;

//@inject("appStore")
//@observer
class ErrorPageNotFound extends Component {

    constructor(props) {

          super(props);



       //   console.log(">>>  store:" + JSON.stringify(store))

		//  store.dispatch("Hello World")

   	 this.state = {
		 height: 0,
		 addSpaceHeight: 100,
             backgroundColor: "black",
           activeColor: "info",
	     currentYear: new Date().getFullYear()
   	};

     }


     componentWillMount() {
	   document.body.style.backgroundColor = "#fff";

     }

	 componentWillUnmount(){
    	   document.body.style.backgroundColor = null;
	 }

	 componentDidMount() {
	     const height = this.divElement.clientHeight;
	      const footerHeight = this.footerElement.clientHeight;

		  console.log(">>> Div Height:" +height);
		  console.log(">>> Footer Height:" +footerHeight);
		  console.log(">>> Window Height:" +viewportHeight);
		  const addSpaceHeight = viewportHeight-height-footerHeight;
	        this.setState({ height: height, addSpaceHeight: addSpaceHeight });
	   }


    setLocale(lang) {

  	  console.log("Locale Lang:" +lang)
           this.props.appStore.locale = lang;
    }


	callbackHeader = (dataFromHeader) => {
		console.log("Good start but not yet there...: " + dataFromHeader);
     }


  render() {
	//console.log("isMobile:" + isMobile)

  //	const { t, i18n } = useTranslation();

  /*	   const changeLanguage = lng => {
  	      i18n.changeLanguage(lng);
  	};*/

    return (
    <Translation>
	    { t =>
	         <div>

					<BrowserView>

							<div ref={ (divElement) => this.divElement = divElement}>

								     <div className="clear-both" style={{height: '10px'}}/>

								<div style={{position: 'relative', background: '#fff', width: '120px', height: '120px',  textAlign: 'center',
								                                 margin: 'auto', marginTop: '10px',}}>
								       <a href="/">
								                               <img src={'https://yinitj5.s3.amazonaws.com/yinitj_logo_transp_bar.png'} style={{height: '80px'}} />
									     </a>
								</div>


							          <div style={{marginTop:140,textAlign:'center', fontSize: '25px', color: '#111', fontWeight: 'bold'}}>{t('Sorry_this_page_isnt_available')}</div>
							           <div style={{marginTop:40,textAlign:'center', fontSize: '16px', color: '#555'}}>{t('Page_doesnt_exist_no_more')}</div>

				                                <div style={{marginTop:40,textAlign:'center', fontSize: '16px', color: '#555'}}>
				                                       {i18n.language =='fr' &&
				                                           <div>Nous vous invitons à retourner sur <a href="/">la page d'accueil</a>.</div>
				                                       }
				                                       {i18n.language =='en' &&
				                                           <div><a href="/">Take me to the homepage</a>.</div>
				                                       }
			                                       </div>

								</div>

					                   <div className="clear-both" style={{height: this.state.addSpaceHeight+'px'}}/>

							    <div ref={ (footerElement) => this.footerElement = footerElement}>
								  <FooterPC />
							   </div>



					</BrowserView>





					{isMobileOnly &&
						<MobileView>

						      <div ref={ (divElement) => this.divElement = divElement}>
								  <div style={{width: '90%', margin: 'auto'}}>
									    <div className="clear-both" style={{height: '10px'}}/>

									<div style={{position: 'relative', background: '#fff', width: '100px', height: '100px',  textAlign: 'center',
									                                 margin: 'auto', marginTop: '10px'}}>
									                            <a href="/">
									                               <img src={'https://yinitj5.s3.amazonaws.com/yinitj_logo_transp_bar.png'} style={{height: '60px'}} />
										    </a>
									</div>

									   <div style={{marginTop:40,textAlign:'center', fontSize: '18px', color: '#111', fontWeight: 'bold'}}>{t('Sorry_this_page_isnt_available')}</div>
									    <div style={{marginTop:40,textAlign:'center', fontSize: '14px', color: '#555'}}>{t('Page_doesnt_exist_no_more')}</div>

									        <div style={{marginTop:40,textAlign:'center', fontSize: '14px', color: '#555'}}>
									                    {i18n.language =='fr' &&
									                        <div>Nous vous invitons à retourner sur <a href="/">la page d'accueil</a>.</div>
									                    }
									                    {i18n.language =='en' &&
									                        <div><a href="/">Take me to the homepage</a>.</div>
									                    }
									          </div>
								 </div>
							</div>

						       <div className="clear-both" style={{height: this.state.addSpaceHeight+'px'}}/>

						        <div ref={ (footerElement) => this.footerElement = footerElement}>
								 <FooterMobile />
							 </div>
					      </MobileView>
					}




					{isTablet &&
						<TabletView>

						      <div ref={ (divElement) => this.divElement = divElement}>

							             <div className="clear-both" style={{height: '10px'}}/>

									<div style={{position: 'relative', background: '#fff', width: '120px', height: '120px',  textAlign: 'center',
									                                 margin: 'auto', marginTop: '10px'}}>
									         <a href="/">
									              <img src={'https://yinitj5.s3.amazonaws.com/yinitj_logo_transp_bar.png'} style={{height: '80px'}} />
										      </a>
									</div>


								   <div style={{marginTop:140,textAlign:'center', fontSize: '25px', color: '#111', fontWeight: 'bold'}}>{t('Sorry_this_page_isnt_available')}</div>
								    <div style={{marginTop:40,textAlign:'center', fontSize: '16px', color: '#555'}}>{t('Page_doesnt_exist_no_more')}</div>

								             <div style={{marginTop:40,textAlign:'center', fontSize: '16px', color: '#555'}}>
								                    {i18n.language =='fr' &&
								                        <div>Nous vous invitons à retourner sur <a href="/">la page d'accueil</a>.</div>
								                    }
								                    {i18n.language =='en' &&
								                        <div><a href="/">Take me to the homepage</a>.</div>
								                    }
								                </div>

							      </div>

							       <div className="clear-both" style={{height: this.state.addSpaceHeight+'px'}}/>

					                      <div ref={ (footerElement) => this.footerElement = footerElement}>
									<FooterTablet />
								 </div>
					     </TabletView>
					}

			        </div>
	  }
	 </Translation>
    )
  }
}




export default ErrorPageNotFound;
