import React, { Component } from 'react';

import { observable, computed, reaction,action,decorate } from "mobx";
import { inject, observer, Provider } from "mobx-react";
import { withTranslation, Translation, } from 'react-i18next';

import { connect } from "react-redux";

import PubSub from 'pubsub-js';


import DeviceOrientation, { Orientation } from 'react-screen-orientation';

import { View, Text, Image, FlatList, ActivityIndicator} from "react-native-web";


import Select from 'react-select';

import Slider from "react-slick";

import { Slide } from 'react-slideshow-image';

import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.scss";

//import {Link} from "react-router-dom";

import * as Scroll from 'react-scroll';
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';



import HamburgerMenu from 'react-hamburger-menu';


//import MetaTags from 'react-meta-tags';
//import HeaderPC from "../header/HeaderPC";

import   FooterMobile from "../../footer/FooterMobile";

import i18n from "../../../i18n";




import { Fade } from 'react-slideshow-image';


var africaCountries = [
		   { value: 'TG', label: i18n.t('TG') },
		   { value: 'CI', label: i18n.t('CI')},
		   { value: 'GH', label: i18n.t('GH')},
		   { value: 'SN', label: i18n.t('SN')},
		   { value: 'NG', label: i18n.t('NG')}
];

var globalOptions = [
	{
	   label: i18n.t('Africa'),
	   options: africaCountries
	},
	{
	   label: i18n.t('America'),
 	   options: [
 		   { value: 'America_empty',  label: i18n.t('Not_available_yet'), isDisabled: true },
 	   ]
	},
	{
	   label: i18n.t('Asia_and_oceania'),
 	   options: [
 		   { value: 'Asia_empty ', label: i18n.t('Not_available_yet'), isDisabled: true },
 	   ]
	},
	{
	   label: i18n.t('Europe'),
 	   options: [
 		   { value: 'Europe_empty', label: i18n.t('Not_available_yet'), isDisabled: true },
 	   ]
	}

];


const fadeProperties = {
  duration: 3500,
  transitionDuration: 500,
  infinite: true,
  arrows: false,
  indicators: false
}


const viewportWidth = window.innerWidth;
const viewportHeight = window.innerHeight;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div  className={className}
      style={{...style, display: "block",position: 'absolute', zIndex: 9, top: '50%', right: '20px' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div  className={className}
      style={{ ...style, display: "block",position: 'absolute', zIndex: 9, top: '50%', left: '20px'}}
      onClick={onClick}
    />
  );
}

//@inject("appStore")
//@observer
class HomePageMobile extends Component {

    constructor(props) {

          super(props);



       //   console.log(">>>  store:" + JSON.stringify(store))

		//  store.dispatch("Hello World")

   	 this.state = {
			 displayPopUp: true,
             backgroundColor: "black",
             activeColor: "info",
		selectedOption: null,
		 options: globalOptions,
		 bodyPos: 0,
		open: false,
	      currentYear: new Date().getFullYear()
   	 };

     }


	 componentWillMount(){
   	   document.body.style.backgroundImage = "#fff";

	   window.addEventListener("orientationchange", function() {
	   	// Announce the new orientation number
		   window.location.reload();
	   }, false);

	 }

	 componentWillUnmount(){
    	   document.body.style.backgroundImage = null;
	 }



	 handleChange = selectedOption => {

		 console.log(">>> selectedOption:" + selectedOption);

	     this.setState(
	       { selectedOption },
	       () => console.log(`Option selected:`, this.state.selectedOption)
	     );

		 window.location.href = "/" + selectedOption.value.toLowerCase();

	  }


	 handleClick() {
		 if (this.state.open)
		 {
			// var _owidth = '0%';
			 document.getElementById("myNav").style.width = "0%";
			 document.body.style.overflow = 'auto';
			 document.body.style.position = 'relative';
			 window.scrollTo(0, this.state.bodyPos);

		 }
		 else
		 {
		 	//var _owidth = '100%';
			var bodyPos  = window.pageYOffset || document.documentElement.scrollTop;
			 this.setState({
				 bodyPos: bodyPos
			 });
			 document.getElementById("myNav").style.width = "100%";
			 document.body.style.overflow = 'hidden';
			 document.body.style.position = 'fixed';
		 }

	 	this.setState({
	 		open: !this.state.open
	 	});
	 }


     setLocale(lang) {

   	  console.log("Locale Lang:" +lang)
         //   this.props.appStore.locale = lang;

 	 	  localStorage.setItem("language", lang);
 	 	  i18n.changeLanguage(lang);

 	   	this.setState({
 	   		open: false
 	   	});

 	    document.getElementById("myNav").style.width = "0%";
 	    document.body.style.overflow = 'auto';
 	   document.body.style.position = 'relative';
 	    window.scrollTo(0, this.state.bodyPos);


		  var _options = [], _africaCountries = [];
		 setTimeout (function(){
			   _africaCountries  = [
	 						   { value: 'TG', label: i18n.t('TG') },
	 						   { value: 'CI', label: i18n.t('CI')},
	 						   { value: 'GH', label: i18n.t('GH')},
	 						   { value: 'SN', label: i18n.t('SN')},
	 						   { value: 'NG', label: i18n.t('NG')}
	 				             ];
			   _africaCountries = _africaCountries.sort((a,b) => a.label.localeCompare(b.label));

			    _options = [
							{
							   label: i18n.t('Africa'),
							   options: _africaCountries
							},
							{
							   label: i18n.t('America'),
						 	   options: [
						 		   { value: 'America_empty',  label: i18n.t('Not_available_yet'), isDisabled: true },
						 	   ]
							},
							{
							   label: i18n.t('Asia_and_oceania'),
						 	   options: [
						 		   { value: 'Asia_empty ', label: i18n.t('Not_available_yet'), isDisabled: true },
						 	   ]
							},
							{
							   label: i18n.t('Europe'),
						 	   options: [
						 		   { value: 'Europe_empty', label: i18n.t('Not_available_yet'), isDisabled: true },
						 	   ]
							}

						];
	     	           _options = _options.sort((a,b) => a.label.localeCompare(b.label));
		  }.bind(this),100);


		 setTimeout (function(){
			     this.setState({
			       options: _options
			     });
		  }.bind(this),200);


     }


		 closePopUp() {
				this.setState({
					displayPopUp: false
				});
		}


		whatchFilm(t) {
			PubSub.publish('launchVideoPop', t);
	 }



  render() {

	  if (viewportWidth  > viewportHeight)
	  {
		  var _n = 2;
	  }
	  else
	  {
	  	var _n = 1;
	  }

	  var settings = {
	    className: "",
	      centerMode: true,
	      infinite: true,
	      centerPadding: "2px",
	      slidesToShow: _n,
	      speed: 500,
		  arrows: true,
	       nextArrow: <SampleNextArrow />,
	        prevArrow: <SamplePrevArrow />
	      };

				let slideImages;

				if (i18n.language == "fr")
				{
						slideImages = [
							{
						    url: 'https://yinitj5.s3.amazonaws.com/cop27/letter_bb_3_phone_fr.jpg',
						    caption: ''
						  },
							{
						    url: 'https://yinitj5.s3.amazonaws.com/cop27/letter_bb_blurred.jpg',
						    caption: ''
						  },
						  {
						    url: 'https://yinitj5.s3.amazonaws.com/cop27/africans_1.jpg',
						    caption: 'Faire de la COP27 la COP Africaine'
						  },
						  {
						    url: 'https://yinitj5.s3.amazonaws.com/youth_world_pc.jpg',
						    caption: 'Inspirer les jeunes à mener les initiatives de transition énergétique'
						  }
						];
				}
				else
				{
						slideImages = [
							{
						    url: 'https://yinitj5.s3.amazonaws.com/cop27/letter_bb_3_phone_en.jpg',
						    caption: ''
						  },
							{
						    url: 'https://yinitj5.s3.amazonaws.com/cop27/letter_bb_blurred.jpg',
						    caption: ''
						  },
						  {
						    url: 'https://yinitj5.s3.amazonaws.com/cop27/africans_1.jpg',
						    caption: 'Make the COP27 the African COP'
						  },
						  {
						    url: 'https://yinitj5.s3.amazonaws.com/youth_world_pc.jpg',
						    caption: "Inspire young people to lead the energy transition initiatives"
						  }
						];
				}

    return (
    <Translation>
	    { t =>
	         <div>






		               <div className="main_header">


						      <div style={{position: 'absolute', top: '2px', left: '15px', zIndex: 9, color: '#fff', fontSize: '17px'}}>
							         <a href="/">
									        <img src={'https://d3van47cb9u9tj.cloudfront.net/debyooid/debyooid_logo_trsp.png'} style={{height: '66px'}} />
									     </a>
									</div>

									<div style={{position: 'absolute', top: '25px', right: '15px', zIndex: 9, color: '#fff', fontSize: '17px'}}>
													 <HamburgerMenu
													 	isOpen={this.state.open}
													 	menuClicked={this.handleClick.bind(this)}
													 	width={35}
													 	height={18}
													 	strokeWidth={1}
													 	rotate={0}
													 	color='black'
													 	borderRadius={0}
													 	animationDuration={0.5}
													 />
									</div>


								  </div>


								  <div id="myNav" className="mobileMenuOverlay"
									style={{height:  viewportHeight-70, fontFamily: 'Helvetica', background: '#fafafa', textAlign: 'center', paddingTop: '30px', lineHeight: '45px', fontSize: '15px'}}>

										    <div  style={{borderBottom: '1px solid #eee', width: '90%', margin: 'auto'}}>
											   <a href="/">
													{t('Home')}
											     </a>
										    </div>

										 <div  style={{borderBottom: '1px solid #eee', width: '90%', margin: 'auto'}}>
												<a href="/support">
													 {t('Support')}
												 </a>
										</div>
										 <div  style={{borderBottom: '1px solid #eee', width: '90%', margin: 'auto'}}>
												<a href="/privacy">
													 {t('Privacy')}
												 </a>
										</div>


									      <div style={{position: 'relative', width: '95px', margin: 'auto', marginTop: '40px'}}>
											   {i18n.language == "fr" &&
												 <div onClick={() => this.setLocale("en")}
												    style={{cursor: 'pointer', position: 'absolute', zIndex: 99, top: '15px', right: '0px', display: 'block', fontSize: '13px', background: 'rgba(255,255,255,0)',
													  borderRadius: '50%', width: '40px', lineHeight: '40px', height: '40px', textAlign: 'center',
													 border: '1px solid #333', color: '#333'}}>
											          EN
											      </div>
											  }
											   {i18n.language == "en" &&
											  <div onClick={() => this.setLocale("en")}
												    style={{cursor: 'pointer', position: 'absolute', zIndex: 99, top: '15px', right: '0px', display: 'block', fontSize: '13px', background: 'rgba(255,255,255,0)',
													  borderRadius: '50%', width: '40px', lineHeight: '40px', height: '40px', textAlign: 'center',
													 border: '1px solid #2489CE', color: '#333'}}>
											          EN
											      </div>
											  }
											   {i18n.language == "fr" &&
											  <div onClick={() => this.setLocale("fr")}
												   style={{cursor: 'pointer', position: 'absolute', zIndex: 99, top: '15px', right: '50px', display: 'block', fontSize: '13px', background: 'rgba(255,255,255,0)',
												   borderRadius: '50%', width: '40px', lineHeight: '40px', height: '40px', textAlign: 'center',
												  border: '1px solid #2489CE', color: '#333'}}>
											             FR
											      </div>
										          }
											   {i18n.language == "en" &&
												  <div onClick={() => this.setLocale("fr")}
												   style={{cursor: 'pointer', position: 'absolute', zIndex: 99, top: '15px', right: '50px', display: 'block', fontSize: '13px', background: 'rgba(255,255,255,0)',
												   borderRadius: '50%', width: '40px', lineHeight: '40px', height: '40px', textAlign: 'center',
												  border: '1px solid #333', color: '#333'}}>
											             FR
											      </div>
										          }
									     </div>
							        </div>


							   <div style={{margin: 'auto', height: '100px'}} />


								 <div style={{position: 'relative', fontFamily: 'Helvetica', background: '#fff', textAlign: 'center', width: '100%', paddingTop: '30px', paddingBottom: '30px'}}>
	                           <div><img src={'https://d3van47cb9u9tj.cloudfront.net/debyooid/debyooid_logo_trsp.png'} style={{height: '180px'}} /></div>

														 <div style={{fontSize: '30px', color: '#111', fontWeight: 'bold', marginTop: '30px'}}>Debyoo ID</div>

														  {i18n.language == "en" && <div style={{fontSize: '18px', color: '#111', marginTop: '30px'}}>One account for everything Debyoo</div>}
															{i18n.language == "fr" && <div style={{fontSize: '18px', color: '#111', marginTop: '30px'}}>Un compte pour tout l'univers Debyoo</div>}

							      </div>

							    	<div className="clear-both" style={{height: '70px',background: '#fff', }}/>



						          <div className="clear-both" style={{height: '60px',background: '#fff', }}/>


							 <FooterMobile />



			        </div>
	  }
	 </Translation>
    )
  }
}



const customStyles = {
  option: (provided, state) => ({
    ...provided,
   // borderBottom: '1px dotted pink',
  //  color: state.isSelected ? 'red' : 'blue',
    paddingLeft: '25px',
	 cursor: state.isDisabled ? 'default' : 'pointer',
  }),
  groupHeading:  (provided) => ({
	  paddingBottom: '5px',
	  color: '#aaa',
	 borderBottom: '1px dotted #ddd',
	  width: '95%',
	  marginLeft: '10px'
   }),
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(HomePageMobile);
