import React from 'react';
import PropTypes from 'prop-types';
import {  Link, withRouter } from 'react-router-dom';
import { observable, computed, reaction,action,decorate } from "mobx";
import { inject, observer, Provider } from "mobx-react";
import { withTranslation, Translation, } from 'react-i18next';
import { connect } from 'react-redux';
import {BrowserView,MobileView,TabletView, isMobileOnly, isIOS, isAndroid} from 'react-device-detect';
import { createBrowserHistory } from "history";

import { Gradient } from 'react-gradient';

import { isLogin } from '../../utils';


import i18n from "../../i18n";



const history = createBrowserHistory();




class HeaderPC extends React.Component {

 constructor(props) {

       super(props);

	//  console.log("0. authenticated:" +JSON.stringify(this.props))

	 let path = this.props.history.location.pathname;

	 console.log("Path:" +JSON.stringify(path));


	 let pathBool = true;
	 if (path.match('signin') || path.match('signup') || path.match('resetpassword') ||
          path.match('home'))
	{
		pathBool = false;
	}

	 this.state = {
		isActive: true,
		pageName: path,
	      showMainHeader: pathBool
	};

	this.handleLinkClick = this.handleLinkClick.bind(this);
  }

  componentDidMount() {




	setTimeout (function(){
		  console.log(">>>>Header: " +JSON.stringify(this.props.store))
	}.bind(this),1000);

	 //  console.log("checked" +checked)

 }




	handleLinkClick(event) {

	/*	setTimeout (function(){
			let path = this.props.history.location.pathname;

			 console.log(">>>2. Path:" +JSON.stringify(path));


			 let pathBool = true;
			 if (path.match('signin') || path.match('signup') || path.match('resetpassword'))
			{
				pathBool = false;
			}

			 this.setState({
				pageName: path,
			      showMainHeader: pathBool
			});
		}.bind(this), 100)

	*/

	}




    setLocale(lang) {

  	  console.log("Locale Lang:" +lang)
        //   this.props.appStore.locale = lang;

	 	  localStorage.setItem("language", lang);
	 	  i18n.changeLanguage(lang);
    }

  toggleMenu =  (e) => {
  	this.setState({
		isActive: !this.state.isActive
  	});
  }



  render() {

	//  const { t, i18n } = this.props;

    return (
         <Translation>
		{
		        t =>
			       <div>



						 <div className="main_header">

						 <div style={{position: 'relative', background: '#fff', width: '100%', height: '70px'}}>

								 <div style={{position: 'absolute', top: '2px', left: '15px', zIndex: 9, color: '#fff', fontSize: '17px'}}>
											 <a href="/">
																<img src={'https://d3van47cb9u9tj.cloudfront.net/debyooid/debyooid_logo_trsp.png'} style={{height: '66px'}} />
											</a>
								 </div>

								 <div className="main_header_link" style={{display: 'none', width: '1000px', margin: 'auto', textAlign: 'center', lineHeight: '70px', fontSize: '18px', fontFamily: 'Lato'}}>
										<Link activeClass="activeMenuLink" className="menu_link" to="menu_challenges" spy={true} smooth={true} offset={-72} duration={700} delay={0}>
										 {t('The_challenges')}
											</Link>
										<Link activeClass="activeMenuLink" className="menu_link" to="menu_youth" spy={true} smooth={true} offset={-72} duration={700} delay={0}>
										 {t('Why_youth')}
											</Link>
										<Link activeClass="activeMenuLink" className="menu_link"  to="menu_mission" spy={true} smooth={true} offset={-72} duration={700} delay={0}>
										 {t('Mission_and_vision')}
											</Link>
										<Link activeClass="activeMenuLink" className="menu_link" to="menu_scope" spy={true} smooth={true} offset={-72} duration={700} delay={0}>
										 {t('Scope_of_action')}
											</Link>
								 </div>

									{i18n.language == "fr" &&
									<div onClick={() => this.setLocale("en")}
										 style={{cursor: 'pointer', position: 'absolute', zIndex: 99, top: '15px', right: '15px', display: 'block', fontSize: '13px', background: 'rgba(255,255,255,0)',
										 borderRadius: '50%', width: '40px', lineHeight: '40px', height: '40px', textAlign: 'center',
										border: '1px solid #333', color: '#333', fontFamily: 'Lato'}}>
												 EN
										 </div>
								 }
									{i18n.language == "en" &&
								 <div onClick={() => this.setLocale("en")}
										 style={{cursor: 'pointer', position: 'absolute', zIndex: 99, top: '15px', right: '15px', display: 'block', fontSize: '13px', background: 'rgba(255,255,255,0)',
										 borderRadius: '50%', width: '40px', lineHeight: '40px', height: '40px', textAlign: 'center',
										border: '1px solid #2489CE', color: '#333', fontFamily: 'Lato'}}>
												 EN
										 </div>
								 }
									{i18n.language == "fr" &&
								 <div onClick={() => this.setLocale("fr")}
										style={{cursor: 'pointer', position: 'absolute', zIndex: 99, top: '15px', right: '65px', display: 'block', fontSize: '13px', background: 'rgba(255,255,255,0)',
										borderRadius: '50%', width: '40px', lineHeight: '40px', height: '40px', textAlign: 'center',
									 border: '1px solid #2489CE', color: '#333', fontFamily: 'Lato'}}>
														FR
										 </div>
											 }
									{i18n.language == "en" &&
									 <div onClick={() => this.setLocale("fr")}
										style={{cursor: 'pointer', position: 'absolute', zIndex: 99, top: '15px', right: '65px', display: 'block', fontSize: '13px', background: 'rgba(255,255,255,0)',
										borderRadius: '50%', width: '40px', lineHeight: '40px', height: '40px', textAlign: 'center',
									 border: '1px solid #333', color: '#333', fontFamily: 'Lato'}}>
														FR
										 </div>
											 }

						 </div>

						</div>

				      </div>



                  }
         </Translation>
     );
  }
}


const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  setBgAction: (payload) => dispatch(setBgAction(payload)),
  setColorAction: (payload) => dispatch(setColorAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderPC));
