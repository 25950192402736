import React from 'react';
import PropTypes from 'prop-types';
import {  Link, NavLink, withRouter } from 'react-router-dom';
import { observable, computed, reaction,action,decorate } from "mobx";
import { inject, observer, Provider } from "mobx-react";
import { withTranslation, Translation, } from 'react-i18next';
import { connect } from 'react-redux';
import {BrowserView,MobileView,TabletView, isMobileOnly, isIOS, isAndroid} from 'react-device-detect';
import { createBrowserHistory } from "history";

import { Gradient } from 'react-gradient';

import { isLogin } from '../../utils';


import i18n from "../../i18n";

import {generateIPForREST} from "../../actions";

const history = createBrowserHistory();





/*
	<Route path="/" exact component={HomePage}/>
	<Route path="/signin" exact component={SigninPage}/>

        <h1>
		{t('greeting')}
        </h1>
        <button onClick={() => this.setLocale("en")} type="text">
          English
        </button>
        <button onClick={() => this.setLocale("uk")} type="text">
          Ukranian
        </button>
*/

//@withNamespaces()
//@inject("appStore")
//@observer
class FooterTablet extends React.Component {

 constructor(props) {

       super(props);

     	 this.state = {
  		 currentYear: new Date().getFullYear()
     	};

	//  console.log("0. authenticated:" +JSON.stringify(this.props))

	/* let path = this.props.history.location.pathname;

	 console.log("Path:" +JSON.stringify(this.props));


	 let pathBool = true;
	 if (path.match('signin') || path.match('signup') || path.match('resetpassword') ||
          path.match('home'))
	{
		pathBool = false;
	}


	 this.state = {
		isActive: true,
		pageName: path,
	      showMainHeader: pathBool,
		 myProfileName: 'Yves Muntu',
		 myProfileSN: 'yves.muntu',
		myProfileThumbnail: 'https://s3.amazonaws.com/debyooinc20.img/default_avatar_normal.jpg'
	};

	this.handleLinkClick = this.handleLinkClick.bind(this);
	this.onSearch = this.onSearch.bind(this);
	this.onNewStory = this.onNewStory.bind(this);

	this.onMenu = this.onMenu.bind(this);*/
  }

  componentDidMount() {




	setTimeout (function(){
		//  console.log(">>>>Header: " +JSON.stringify(this.props.store))
	}.bind(this),1000);

	 //  console.log("checked" +checked)

 }




	handleLinkClick(event) {

	/*	setTimeout (function(){
			let path = this.props.history.location.pathname;

			 console.log(">>>2. Path:" +JSON.stringify(path));


			 let pathBool = true;
			 if (path.match('signin') || path.match('signup') || path.match('resetpassword'))
			{
				pathBool = false;
			}

			 this.setState({
				pageName: path,
			      showMainHeader: pathBool
			});
		}.bind(this), 100)

	*/

	}




  setLocale(lang) {

	  console.log("Locale Lang:" +lang);

	  localStorage.setItem("language", lang);
	  i18n.changeLanguage(lang);

	  if (isLogin())
	  {
		  this.saveLanguageToServer(lang);
	  }
       //  this.props.appStore.locale = lang;
  }


  saveLanguageToServer(lang) {
	//var ip = "https://www.debyoo.com/";
	var ip = generateIPForREST();

	var jwtToken = localStorage.getItem("jwtToken") || '';
	//  this.props.setLanguage(responseData.country_code);
	fetch(ip+'save_language_to_server_api', {
                            method: 'POST',
                            headers: {
                              'Accept': 'application/json',
			    'Authorization': jwtToken,
                              'Content-Type': 'application/json'
                            },
			   credentials: 'include',
                            body: JSON.stringify({
				    language: lang
                            })
                    })
	  	.then((response) => response.json())
	  	.then((responseData) => {
			console.log("Language saved on server");

             })
            .catch(function(error) {

	     }.bind(this));

  }

  toggleMenu =  (e) => {
  	this.setState({
		isActive: !this.state.isActive
  	});
  }



  render() {

	//  const { t, i18n } = this.props;

    return (
         <Translation>
		{
		        t =>
			      <div>



			                          <div style={{clear: 'both', height: '50px'}} />




						             <div style={{clear: 'both', height: '50px'}} />
						             <div style={{clear: 'both',  margin: 'auto', borderTop: '1px solid #ddd'}} />
						             <div style={{clear: 'both', height: '20px'}} />

							<div style={{textAlign: 'center', width: '80%', margin: 'auto', background: '#fff', fontSize: '13px', fontWeight: 'normal'}}>
							      <div style={{color: '#555', fontSize: '16px', fontWeight: 'normal', textAlign: 'center',fontFamily: 'Lato'}}>
							         © {this.state.currentYear}  • Debyoo ID&nbsp;&nbsp;|&nbsp;&nbsp;{i18n.language == "fr" && <span>Propulsée par</span>}{i18n.language == "en" && <span>Powered by</span>} <a href={'https://www.debyoo.com'} target="_blank">Debyoo</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href='/support'> {t('Support')}</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href='/privacy'> {t('Privacy')}</a>&nbsp;&nbsp;|&nbsp;&nbsp;Contact: <a href="mailto:info@debyoo.com">info@debyoo.com</a>
							      </div>
							</div>

						        <div style={{clear: 'both', height: '20px'}} />

			      </div>

                  }
         </Translation>
     );
  }
}


/*
const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  setBgAction: (payload) => dispatch(setBgAction(payload)),
  setColorAction: (payload) => dispatch(setColorAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderConnectedPC));
*/

export default withRouter(FooterTablet);
