import React, { Component } from 'react';

import { observable, computed, reaction,action,decorate } from "mobx";
import { inject, observer, Provider } from "mobx-react";
import { withTranslation, Translation, } from 'react-i18next';

import {Link} from "react-router-dom";
import { Fade } from 'react-slideshow-image';

//import MetaTags from 'react-meta-tags';

import {BrowserView,MobileView,TabletView} from 'react-device-detect';
//isBrowser,isMobile, isTablet

import HeaderPC from "../../header/HeaderPC";
import HeaderMobile from "../../header/HeaderMobile";
import HeaderTablet from "../../header/HeaderTablet";
import HeaderConnectedPC from "../../header/HeaderConnectedPC";
import FooterPC from "../../footer/FooterPC";
import FooterMobile from "../../footer/FooterMobile";
import FooterTablet from "../../footer/FooterTablet";

import { isLogin } from '../../../utils';

const fadeImages = [
  'https://s3.amazonaws.com/debyooinc16.img/home/home_img_stories.png',
  'https://s3.amazonaws.com/debyooinc16.img/home/Publish_SP4_06.png',
  'https://s3.amazonaws.com/debyooinc16.img/home/home_img_feels4.png'
];

const fadeProperties = {
  duration: 3500,
  transitionDuration: 500,
  infinite: true,
  arrows: false,
  indicators: false
}

//@inject("appStore")
@observer
class PrivacyPage extends Component {
    constructor(props) {

          super(props);



   	 this.state = {
		 currentYear: new Date().getFullYear()
   	};

     }

	 componentWillMount(){
   	   document.body.style.backgroundImage = "url('https://s3.amazonaws.com/debyooinc20.img/about/debyoo_connecting_bg_2.png')";
	   document.body.style.backgroundRepeat = 'no-repeat';
	   document.body.style.backgroundAttachment = 'fixed';
	 }

	 componentWillUnmount(){
    	   document.body.style.backgroundImage = null;
 	   document.body.style.backgroundRepeat = null;
 	   document.body.style.backgroundAttachment = null;
	 }

    setLocale(lang) {

  	  console.log("Locale Lang:" +lang)
           this.props.appStore.locale = lang;
    }


  render() {
	//console.log("isMobile:" + isMobile)

  //	const { t, i18n } = useTranslation();

  /*	   const changeLanguage = lng => {
  	      i18n.changeLanguage(lng);
  	};*/

    return (
    <Translation>
	    { t =>
	         <div>

					<BrowserView>

				                  {isLogin() && 1==2 &&
								<HeaderConnectedPC callbackHeaderFromParent={this.callbackHeader} store={this.props.store}/>
						      }

						       {!isLogin() &&
								<HeaderPC store={this.props.store}/>
						      }


			                         <div className="clear-both h40" style={{height: 60}}/>

				        <div className="white-round-container" style={{margin: 'auto', marginTop: 60, width: '1000px'}}>
			              <div className="padding-20" style={{fontFamily: 'Lato'}}>
						                    <div className="page-title">
			                                                  {t('Privacy')}
						                     </div>

									<br/><br/>

						         		<b>1.  Policy privacy</b>
									 <br/><br/>

								           We are committed to providing you relevant content along with excellent service. In order to accomplish this, we gather, use and share certain personal information. Our online privacy policy describes what we gather, with whom we share it, and how you may direct us not to share certain information with certain parties. Because the security of your personal information is very important to us, we restrict access to your nonpublic personal information to those employees who need to know that information to provide products or services to you. We also maintain physical, electronic and procedural safeguards that comply with federal regulations to protect your nonpublic personal information.
									<br/><br/>


                  <b>2. Information collection</b>
                  <br/><br/>


                  We collect information from you when you register on our site or our mobile app, or when you apply for membership on our mobile app. When registering on our site or our mobile app, as appropriate, you will be asked to enter your: name and e-mail address. You may, however, visit our site anonymously.
                     <br/><br/>


                  <b>3. Use and disclosure of information</b>
                  <br/><br/>

                 We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information. We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information.

                  <br/><br/>

									<b>4.  Changes to this Policy</b>
									<br/><br/>

									We may make changes to this Privacy Policy at any time. We will communicate any such changes by updating this page, notifying you by email if you have registered your email address with us, or in writing, as required by law
								     <br/><br/>


									<b>5.   Children&rsquo;s privacy</b>
									<br/><br/>

									The Children&rsquo;s Online Privacy Protection Act imposes certain requirements on web sites directed at children under 13 that collect information on those children, and on web sites that knowingly collect information on children under 13. Youth Initiative Jeunesse and yinitj.com are not directed at children under 13, and does not knowingly collect any personal information from children under 13.
								     <br/><br/>


									<b>6.    Links to other sites</b>
									<br/><br/>

									Yinitj.com contains links to web sites operated by other third parties. Yinitj.com has no control over, or responsibility for, their content, information or activities, including security and use of personal information. We encourage you to read the privacy policies and terms of use of any third-party site before submitting any personal information.
									<br/><br/>

									<b>7.	Online account registration</b>
									<br/><br/>

									Your registered information will be securely stored with us, and you can add, delete, or change your registered information at any time using your password.
								   <br/><br/>


									<b>8.	E-mails</b>
									<br/><br/>

									We may send promotional e-mails to you only if you have asked to receive them. Each time you receive an e-mail from us, you will be given the opportunity to "opt-out" or stop receiving them.
									<br/><br/>


									<b>9. In Summary</b>
									<br/><br/>
									 Youth Initiative Jeunesse is committed to protecting your privacy. The information collected from our Site helps us better understand our market and consumer interests. This allows us to serve our customers and business associates with the level of satisfaction they deserve.
									<br/><br/>


									<br/><br/><br/>


									Last modified: September, 2022



								</div>
				                     </div>




							 <div className="clear-both" style={{height: 100}}/>

							  <div style={{background: '#fff', paddingTop: 5, paddingBottom: 0, borderRadius: 4}}>
								<FooterPC />
							  </div>


					</BrowserView>





					<MobileView>
              {!isLogin() &&
                 <HeaderMobile store={this.props.store}/>
              }


              <div className="clear-both h40" style={{height: 60}}/>

               <div className="white-round-container" style={{margin: 'auto', marginTop: 60, width: '94%'}}>
                   <div className="padding-20" style={{fontFamily: 'Lato'}}>
                               <div className="page-title">
                                                       {t('Privacy')}
                                </div>

                 <br/><br/>

                       <b>1.  Policy privacy</b>
                  <br/><br/>

                          We are committed to providing you relevant content along with excellent service. In order to accomplish this, we gather, use and share certain personal information. Our online privacy policy describes what we gather, with whom we share it, and how you may direct us not to share certain information with certain parties. Because the security of your personal information is very important to us, we restrict access to your nonpublic personal information to those employees who need to know that information to provide products or services to you. We also maintain physical, electronic and procedural safeguards that comply with federal regulations to protect your nonpublic personal information.
                 <br/><br/>


                 <b>2. Information collection</b>
                 <br/><br/>


                 We collect information from you when you register on our site or our mobile app, or when you apply for membership on our mobile app. When registering on our site or our mobile app, as appropriate, you will be asked to enter your: name and e-mail address. You may, however, visit our site anonymously.
                    <br/><br/>


                 <b>3. Use and disclosure of information</b>
                 <br/><br/>

                We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information. We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information.

                 <br/><br/>

                 <b>4.  Changes to this Policy</b>
                 <br/><br/>

                 We may make changes to this Privacy Policy at any time. We will communicate any such changes by updating this page, notifying you by email if you have registered your email address with us, or in writing, as required by law
                    <br/><br/>


                 <b>5.   Children&rsquo;s privacy</b>
                 <br/><br/>

                 The Children&rsquo;s Online Privacy Protection Act imposes certain requirements on web sites directed at children under 13 that collect information on those children, and on web sites that knowingly collect information on children under 13. Youth Initiative Jeunesse and yinitj.com are not directed at children under 13, and does not knowingly collect any personal information from children under 13.
                    <br/><br/>


                 <b>6.    Links to other sites</b>
                 <br/><br/>

                 Yinitj.com contains links to web sites operated by other third parties. Yinitj.com has no control over, or responsibility for, their content, information or activities, including security and use of personal information. We encourage you to read the privacy policies and terms of use of any third-party site before submitting any personal information.
                 <br/><br/>

                 <b>7.	Online account registration</b>
                 <br/><br/>

                 Your registered information will be securely stored with us, and you can add, delete, or change your registered information at any time using your password.
                  <br/><br/>


                 <b>8.	E-mails</b>
                 <br/><br/>

                 We may send promotional e-mails to you only if you have asked to receive them. Each time you receive an e-mail from us, you will be given the opportunity to "opt-out" or stop receiving them.
                 <br/><br/>


                 <b>9. In Summary</b>
                 <br/><br/>
                  Youth Initiative Jeunesse is committed to protecting your privacy. The information collected from our Site helps us better understand our market and consumer interests. This allows us to serve our customers and business associates with the level of satisfaction they deserve.
                 <br/><br/>


                 <br/><br/><br/>


                 Last modified: September, 2022



               </div>
                            </div>




              <div className="clear-both" style={{height: 100}}/>

               <div style={{background: '#fff', paddingTop: 5, paddingBottom: 0, borderRadius: 4}}>
               <FooterMobile />
               </div>
					</MobileView>




					<TabletView>
                  {!isLogin() &&
                     <HeaderTablet store={this.props.store}/>
                  }


                  <div className="clear-both h40" style={{height: 60}}/>

                   <div className="white-round-container" style={{margin: 'auto', marginTop: 60, width: '80%'}}>
                       <div className="padding-20" style={{fontFamily: 'Lato'}}>
                                   <div className="page-title">
                                                           {t('Privacy')}
                                    </div>

                     <br/><br/>

                           <b>1.  Policy privacy</b>
                      <br/><br/>

                              We are committed to providing you relevant content along with excellent service. In order to accomplish this, we gather, use and share certain personal information. Our online privacy policy describes what we gather, with whom we share it, and how you may direct us not to share certain information with certain parties. Because the security of your personal information is very important to us, we restrict access to your nonpublic personal information to those employees who need to know that information to provide products or services to you. We also maintain physical, electronic and procedural safeguards that comply with federal regulations to protect your nonpublic personal information.
                     <br/><br/>


                     <b>2. Information collection</b>
                     <br/><br/>


                     We collect information from you when you register on our site or our mobile app, or when you apply for membership on our mobile app. When registering on our site or our mobile app, as appropriate, you will be asked to enter your: name and e-mail address. You may, however, visit our site anonymously.
                        <br/><br/>


                     <b>3. Use and disclosure of information</b>
                     <br/><br/>

                    We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information. We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information.

                     <br/><br/>

                     <b>4.  Changes to this Policy</b>
                     <br/><br/>

                     We may make changes to this Privacy Policy at any time. We will communicate any such changes by updating this page, notifying you by email if you have registered your email address with us, or in writing, as required by law
                        <br/><br/>


                     <b>5.   Children&rsquo;s privacy</b>
                     <br/><br/>

                     The Children&rsquo;s Online Privacy Protection Act imposes certain requirements on web sites directed at children under 13 that collect information on those children, and on web sites that knowingly collect information on children under 13. Youth Initiative Jeunesse and yinitj.com are not directed at children under 13, and does not knowingly collect any personal information from children under 13.
                        <br/><br/>


                     <b>6.    Links to other sites</b>
                     <br/><br/>

                     Yinitj.com contains links to web sites operated by other third parties. Yinitj.com has no control over, or responsibility for, their content, information or activities, including security and use of personal information. We encourage you to read the privacy policies and terms of use of any third-party site before submitting any personal information.
                     <br/><br/>

                     <b>7.	Online account registration</b>
                     <br/><br/>

                     Your registered information will be securely stored with us, and you can add, delete, or change your registered information at any time using your password.
                      <br/><br/>


                     <b>8.	E-mails</b>
                     <br/><br/>

                     We may send promotional e-mails to you only if you have asked to receive them. Each time you receive an e-mail from us, you will be given the opportunity to "opt-out" or stop receiving them.
                     <br/><br/>


                     <b>9. In Summary</b>
                     <br/><br/>
                      Youth Initiative Jeunesse is committed to protecting your privacy. The information collected from our Site helps us better understand our market and consumer interests. This allows us to serve our customers and business associates with the level of satisfaction they deserve.
                     <br/><br/>


                     <br/><br/><br/>


                     Last modified: September, 2022



                   </div>
                                </div>




                  <div className="clear-both" style={{height: 100}}/>

                   <div style={{background: '#fff', paddingTop: 5, paddingBottom: 0, borderRadius: 4}}>
                   <FooterTablet />
                   </div>

					 </TabletView>


       </div>
	  }
	 </Translation>
    )
  }
}



export default PrivacyPage;
